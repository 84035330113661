export class Pagination {
  size: number;
  skip: number;
  order: string | undefined;
  sort: 'desc' | 'asc';

  constructor(data?: PaginationModel) {
    if (typeof data === 'undefined') {
      this.sort = 'desc';
      this.size = 10;
      this.skip = 0;
      this.order = '';
    } else {
      this.size = data?.size || 10;
      this.skip = data?.skip || 0;
      this.order = data?.order || '';
      this.sort = data?.sort || 'desc';
    }
  }
}

export interface PaginationModel {
  size?: number;
  skip?: number;
  order?: string;
  sort?: 'desc' | 'asc';
}
